<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
           
      transactions:[],
    Transactions:{},
          page:1,
            limit:20,
            tot_pages:0,
            searchTransactions:null,
            TransactionsSearchMode:false,
           // appprices:[]

    };
  },
methods:{
  addTransactions(){
      var data = {
        title: "popups.addUsers",
        inputs:[{"model":"id",
"type":"text",
"label":"id"
},

{"model":"debit_level_three_chart_of_account_id",
"type":"text",
"label":"debit_level_three_chart_of_account_id"
},

{"model":"credit_level_three_chart_of_account_id",
"type":"text",
"label":"credit_level_three_chart_of_account_id"
},

{"model":"value",
"type":"text",
"label":"value"
},

{"model":"status",
"type":"text",
"label":"status"
},

{"model":"payment_date",
"type":"text",
"label":"payment_date"
},

{"model":"descr",
"type":"text",
"label":"descr"
},

{"model":"created",
"type":"text",
"label":"created"
},

{"model":"updated",
"type":"text",
"label":"updated"
},

],
        buttons: [
          {
            text: "add", closer: true, color: "primary", handler: (obj) => {
              this.http.post("transactions",obj).then(() => {
                this.get(this.page)
              })
            }
          
          },
          {
            text: "Cancel", closer: true, color: "danger", handler: () => {
              console.log("Like Clicked")
            }
          },
        ]

      }
      this.popup.modal(data)
 

  },
     search(){
      this.TransactionsSearchMode=true
      this.tot_pages = 0
      this.http.post("transactions/search",{search:this.searchTransactions})
      .then(res=>{
         this.transactions = res.data
      })
    },
  cancelappsearchMode(){
    this.searchTransactions = ""
    this.TransactionsSearchMode= false
    this.get(this.page)
  },
getTransactions(){
this.http.get("transactions").then((res)=>{
this.transactions = res.data
})
},
deleteTransactions(app){
          var data = {
    title:'popups. deletesms',
    template:`
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
                buttons:[
            {text:"Delete",closer:true,color:"danger",handler:()=>{
              console.log(app)
              this.http.delete("transactions",app.id).then(()=>{
                this.get(this.page)
              })
            }},
            {text:"Cancel",closer:true,color:"light",handler:()=>{
              console.log("Like Clicked")
            }},
        ]
        }
         this.popup.modal(data)
  },

  get(page){
    console.log(page)
     this.http.post('transactions/pagination',{
      limit:this.limit,
      page:page
     }).then((res)=>{
      this.page = page
    this.tot_pages = Math.ceil(res.tot/this.limit)
    this.transactions = res.data
       })}
      
    },
      created(){
      this.get(1)
      this.getTransactions()
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Payment App" />
    <button type="button" @click="addTransactions()" class="btn btn-light float-end mb-4" data-bs-toggle="modal" data-bs-target="#Add"><span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important;"></span></button>

<table class="table">
<thead>
<tr class=" text-light text-center" style="background-color: #2a3042 !important;">
<th scope='col'>id</th>
<th scope='col'>debit_level_three_chart_of_account_id</th>
<th scope='col'>credit_level_three_chart_of_account_id</th>
<th scope='col'>value</th>
<th scope='col'>status</th>
<th scope='col'>payment_date</th>
<th scope='col'>descr</th>
<th scope='col'>created</th>
<th scope='col'>updated</th>
  
</tr>
</thead>
<tbody>
     <tr v-for="(app,index) in transactions" :key="app" class="text-center">
        <td>{{index+1}}</td>

        <td>
          <img v-bind:src="app.img" class="avatar-sm">
           </td>
         <td>{{transactions.id}}</td><td>{{transactions.debit_level_three_chart_of_account_id}}</td><td>{{transactions.credit_level_three_chart_of_account_id}}</td><td>{{transactions.value}}</td><td>{{transactions.status}}</td><td>{{transactions.payment_date}}</td><td>{{transactions.descr}}</td><td>{{transactions.created}}</td><td>{{transactions.updated}}</td>
           <button type="button" class="btn btn-outline-light" style="margin-right: 1rem !important;" data-bs-toggle="tooltip" data-bs-placement="top" title="vdio">
      <i class="bx bxs-video font-size-24 text-danger fa-9x" data-bs-toggle="modal" data-bs-target="#video"></i>
       </button>
           <td> <img v-bind:src="app.icon" class="avatar-sm"></td>
           <td>{{app.p_lang}}</td>
           <td>{{app.created}}</td>
           
<td>  
<div class="row" text-center>
        <div class="col-3 mt-2 ms-5" >
       <b-form-checkbox switch></b-form-checkbox></div>
         <div class="col-3">
          <router-link  :to="'/app_dashboard/'+app.id" type="button" class="btn btn-outline-light" style="margin-right: 1rem !important;">
      <i class="bx bxs-edit-alt  font-size-24 text-success fa-9x" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"></i>
    </router-link> 
        
  </div>
  <div class="col-3">
    <button type="button" class="btn btn-light" @click="deleteTransactions(app)">
      <i class="mdi mdi-trash-can font-size-18 text-danger fa-9x"></i>
       </button>
  </div>

</div></td>
        </tr>
        </tbody>
        </table>
<!-- end  table -->
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Modal video </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
  <video style="width:470px;height:360px"  controls>
<source src="/Rec 0008.mp4" type="video/mp4">
<source src="/Rec 0008.mp4" type="video/ogg">
<source src="/Rec 0008.mp4" type="video/webm">
<source src="/Rec 0008.mp4" type="video/x-flv">


  </video>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  
  </div>
</div>
</div>

<!--end-->
<!-- Modal -->
<div class="modal fade w-100" id="Add" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="section">
  <h1>Basic Information</h1>

 
<div class="row">

</div>
<div class="row">

<div class="col-12 col-md-6 ">
                <label class="form-label mt-2"> Programming Language </label>
                  <select class="form-select" v-model="c">
                <option>  Python</option>
                <option>PHP</option>
                <option> NodeJS</option>
                <option> Java</option>
        
                   </select>
</div>
<div class="col">
  <label class="form-label  mt-2">Icon </label>
  <input type="file" class="form-control">
</div>
</div>
</div>
</div>


<br><!--end--><br>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Add</button>
    <button type="button" class="btn btn-primary">Close</button>
  </div>
</div>
</div></div>
<!--end-->
<!--   Apps  Pagination     -->
<ul class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{disabled:page==1}">
      <a
        class="page-link"
        href="javascript: void(0);"
         @click="get(page-1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{active:(p==page)}" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);"  @click="get(p)">{{p}}</a>
    </li>

    <li class="page-item" :class="{disabled:page==total_pages}">
      <a
        class="page-link"
        href="javascript: void(0);"
         @click="get(page+1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
<!--end-->
  </Layout>
</template>




